import moment from 'moment'
import { remove } from 'firebase/database'
import { getCurrentInstance } from 'vue'

export const partnerProducts = [
  'Financial services',
  'Life Insurance',
  'Mortgage broker',
  'P&C',
  'Pet',
  'Real Estate',
  'Travel',
  'Other'
]
export const applicationSteps = [
  'pre-application',
  'pre-application',
  'policy-options',
  'underwritting',
  'personal-statements',
  'beneficiary',
  'your-policy',
  'payment',
  'thanks'
]

export function loadMenu (data) {
  let CaMenu = {}
  const getLocale = data
  if (getLocale === 'fr') {
    CaMenu = {
      CA: [
        { title: 'Term Life', route: '/canadianterm', to: '/canadianterm', icon: 'mdi-hand-heart-outline' },
        { title: 'Covered By Blanket Brokerage', route: '/bnmdashboard', to: '/bnmdashboard' }
      ]
    }
  } else {
    CaMenu = {
      CA: [
        // { title: 'Pet', route: '/pet-ca', to: '/pet-ca', icon: 'mdi-paw' },
        // { title: 'Term Life', route: '/termlifehome' },
        { title: 'Term Life', route: '/canadianterm', to: '/canadianterm', icon: 'mdi-hand-heart-outline' },
        { title: 'Travel', route: '/travel/ca', to: '/travel/ca', icon: 'mdi-airplane' },
        { title: 'Renters', route: '/renters-ca', to: '/renters-ca', icon: 'mdi-key-chain-variant' },
        { title: 'Covered By Blanket Brokerage', route: '/bnmdashboard', to: '/bnmdashboard' }
      ]
    }
  }
  return { ...CaMenu }
}

export function checkCaTermPage () {
  if (window.location.href.indexOf('/canadianterm/survey') > -1) {
    return true
  }
}

export const formattedDate = (locale, date = new Date(),) => {
  return Intl.DateTimeFormat(locale === 'fr' ? 'fr-FR' : 'en-US').format(date)
}
export const localCurrency = (locale, a = 0, currencySymbol = '$') => {
  const getAmount = a < 0 ? 0 : a
  const isDecimal = !Number.isInteger(getAmount)
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: isDecimal ? 2 : 0
  }).format(getAmount)
  const getWithoutSpace = formattedAmount.replace(/(\s)(?=\d)/g, ',')
  const withoutCode = formattedAmount.replace(/[\d.,\s-]/g, '').trim()
  return getWithoutSpace.replace(withoutCode, currencySymbol).trim()
}

export function isProductAvailable () {
  if (location.pathname === '/travelinfocanada' ||
    location.pathname === '/pet/infoca' ||
    location.pathname === '/renters/infoca') {
    return false
  }
  return true
}

export const siteInfo = {
  email: 'customerservice@blanket.com',
  phone: '1-833-625-4353'
}
export const replacePhone = (txt, phone = '1-833-625-4353') => {
  txt.replaceAll('%%phone%%', phone)
}
export const replaceEmail = (txt, email = 'customerservice@blanket.com') => {
  txt.replaceAll('%%email%%', email)
}
export const currentApplications = data => {
  if (!data) {
    return []
  }
  return Object.keys(data)
    .map(key => ({ id: key, ...data[key] }))
    .filter(({ status }) => status !== 'completed')
    .map(({
            user,
            createdAt,
            applicationStep,
            id
          }) => ({
      ...user,
      id,
      name: `${user.firstName} ${user.lastName}`,
      dob: moment(user.dob).format('YYYY-MM-DD'),
      applicationStep,
      createdAt: moment(createdAt).format('YYYY-MM-DD')
    })).reverse()
}

export function getDefaultValue (val) {
  return (val !== undefined && val !== null) ? val : 0
}

export const DATE_FORMAT_YYYY = 'YYYY-MM-DD'

export const sortObject = (obj) => {
  const arrayOfKeyValuePairs = Object.entries(obj)

  // Sort the array by the specified property
  arrayOfKeyValuePairs.sort(([, valueA], [, valueB]) => {
    if (valueA.order < valueB.order) return -1
    if (valueA.order > valueB.order) return 1
    return 0
  })
  // Convert sorted array back to object
  const sortedObj = Object.fromEntries(arrayOfKeyValuePairs)

  return sortedObj
}

export const deepCopy = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const copy = Array.isArray(obj) ? [] : {}

  for (const [key, value] of Object.entries(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(value)
    }
  }

  return copy
}

export const deleteCompletedApps = async (snapshot, ref) => {
  try {
    if (snapshot && ref) {
      const updates = {}
      Object.keys(snapshot).forEach((key) => {
        if (snapshot[key]?.status === 'completed') {
          updates[key] = null
        }
      })
      if (Object.keys(updates).length > 0) {
        await ref.update(updates)
        console.log('Completed applications deleted successfully.')
      } else {
        console.log('No completed applications to delete.')
      }
    } else {
      console.error('Snapshot or ref is not defined.')
    }
  } catch (error) {
    console.error('Error deleting completed applications:', error)
  }
}

export const keepLastSevenApps = async (apps, ref) => {
  const appIds = Object.keys(apps)
  if (appIds.length > 6) {
    appIds.sort((a, b) => new Date(apps[b].createdAt) - new Date(apps[a].createdAt))
    const appIdsToDelete = appIds.slice(6)
    const updates = {}
    Object.keys(apps).forEach((key) => {
      if (appIdsToDelete.includes(key)) {
        console.log('app to delete', key)
        updates[key] = null
      }
    })
    if (Object.keys(updates).length > 0) {
      await ref.update(updates)
      console.log('Completed applications deleted successfully.')
    }
    console.log(`Deleted ${appIdsToDelete.length} old application(s).`)
  } else {
    console.log('No apps to delete. User has 7 or fewer applications.')
  }
}
export const getCopyURL = ({ type, partnerurl }) => {
  const origin = window.location.origin
  console.log({ type })
  if (type?.toLowerCase() === 'cst') {
    return `${origin}/canadianterm/partner/${partnerurl}`
  }
  if (type?.toLowerCase() === 'gga') {
    return `${origin}/travel/usa/partner/${partnerurl}`
  }
  return `${origin}/partner/${partnerurl}`
}
export const gtag_report_conversion = ({ event }, send_to) => {
  /* try {
    let sendEvent = {
      currency: 'CAD',
      value: 1.0
    }

    if (send_to.includes('jDGGCNbS2MEZEJC0zf49') || send_to.includes('Pm--CIbXsMMZEJC0zf49')) {
      sendEvent = {
        currency: 'CAD'
      }
    }
    event('conversion', {
      send_to,
     ...sendEvent
    })
  } catch (e) {
    console.log({ e })
  } */
}
export const checkRoute = () => {
  const allowedRoutes = [
    '/canadianterm/survey',
    '/travel/infousa',
    '/travel/selectplanusa',
    '/travel/payusa'
  ]
  const routeFound = allowedRoutes.some(route => window.location.pathname.startsWith(route))
  if (!routeFound) {
    return false
  }
  return true
}
export const GT_EVENT = 'conversion'
export const GT_FORM_START = 'AW-16640072208/faW7CLfWqcMZEJC0zf49'
